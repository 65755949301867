import React, { Component } from 'react';
class TextBox extends Component {
  render() {
    return (
      <div key={this.props.input.id} className="form-group">
        {this.props.input.name_label ? (
          <label className="input-label-form" htmlFor={this.props.input.input_id}>
            {this.props.input.name_label}
            {this.props.input.required ? <span className="required-span">*</span> : null}
          </label>
        ) : null}
        <input
          type={this.props.input.input_type}
          defaultValue={this.props.input.value}
          onChange={this.props.changeHandler}
          onBlur={this.props.blurHandler}
          className={this.props.input.required && this.props.form_submit && this.props.input.error ? 'form-control input-error' : 'form-control'}
          id={this.props.input.input_id}
          name={this.props.input.name ? this.props.input.name : this.props.input.input_id}
          required={this.props.input.required ? true : false}
          autoFocus={this.props.input.autoFocus ? this.props.input.autoFocus : false}
          placeholder={this.props.input.place_holder}
          pattern={this.props.input.pattern ? this.props.input.pattern : ''}
          maxLength={this.props.input.maxLength ? this.props.input.maxLength : ''}
        />
        {this.props.input.required && this.props.form_submit && this.props.input.error ? (
          this.props.input.value && this.props.input.error_message ? (
            <small className="error-message">{this.props.input.error_message}</small>
          ) : (
            <small className="error-message">Please complete the required field.</small>
          )
        ) : null}
      </div>
    );
  }
}
export default TextBox;
