import React, { useState, useEffect } from 'react';
import './header.scss';
import logo from '../../static/logo.svg';
function Header(props) {
  const [header, setHeader] = useState('navbar navbar-expand-lg navbar-light fixed-top py-3 px-sm-4');

  const listenScrollEvent = (event) => {
    if (window.scrollY > 0) {
      return setHeader('navbar navbar-expand-lg navbar-light fixed-top py-3 px-sm-4 nav-fade-on-scroll');
    } else {
      return setHeader('navbar navbar-expand-lg navbar-light fixed-top py-3 px-sm-4');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  // render() {
  return (
    <nav id="inbrace-nav" className={header}>
      {' '}
      <a className="navbar-brand" href="https://inbrace.com/">
        <img src={logo} alt="InBrace" className="img-fluid" />
      </a>
      <a id="inbrace-locator" href="https://inbrace.com/finder/" className="d-inline-block d-lg-none ml-auto mr-3 text-center">
        <i className="zmdi zmdi-pin"></i>
      </a>
      <button
        id="inbrace-menu"
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#inbrace-menu-collapse"
        aria-controls="inbrace-menu-collapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {' '}
        <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span>{' '}
      </button>
      <div id="inbrace-menu-collapse" className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto ml-0 ml-lg-2 ml-xl-4 my-4 my-lg-0">
          <li className="nav-item">
            {' '}
            <a className="nav-link" href="https://inbrace.com/technology/">
              Technology
            </a>
          </li>
          <li className="nav-item">
            {' '}
            <a className="nav-link" href="https://inbrace.com/for-patients/">
              <span className="d-inline-block d-lg-none d-xl-inline-block">For&nbsp;</span>Patients
            </a>
          </li>
          <li className="nav-item">
            {' '}
            <a className="nav-link" href="https://inbrace.com/for-doctors/">
              <span className="d-inline-block d-lg-none d-xl-inline-block">For&nbsp;</span>Doctors
            </a>
          </li>
          <li className="nav-item">
            {' '}
            <a className="nav-link" href="https://inbrace.com/testimonials/">
              Testimonials
            </a>
          </li>
          <li className="nav-item">
            {' '}
            <a className="nav-link" href="https://inbrace.com/about-us/">
              About<span className="d-inline-block d-lg-none d-xl-inline-block">&nbsp;Us</span>
            </a>
          </li>
        </ul>
        <ul className="navbar-nav ml-auto d-none d-lg-flex">
          <li className="nav-item">
            {' '}
            <a className="nav-link" href="https://portal.inbrace.com/" target="_blank" rel="noopener noreferrer">
              Login
            </a>
          </li>
          <li className="nav-item">
            {' '}
            {/*eslint-disable-next-line*/}
            <a className="nav-link border-left mr-2" href={props.provider_link}>
              Become a Provider
            </a>
          </li>
          <li className="nav-item">
            {' '}
            <a className="nav-link btn btn-outline rounded-pill px-3 px-lg-3 px-xl-4" href="https://inbrace.com/finder/">
              <i className="zmdi zmdi-pin text-pink mr-2"></i> Find a Doctor
            </a>{' '}
          </li>
        </ul>
        <ul className="navbar-nav d-flex d-lg-none">
          <li className="nav-item my-2">
            <a className="btn btn-outline rounded-pill p-2 d-block" href="https://portal.inbrace.com/" target="_blank" rel="noopener noreferrer">
              Login
            </a>
          </li>
          <li className="nav-item my-2">
            <a className="btn btn-outline rounded-pill p-2 d-block" href="http://info.inbrace.com/provider-inquiry" target="_blank" rel="noopener noreferrer">
              Become a Provider
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
// }
export default Header;
