import React, { Component } from 'react';
import './submission.scss';
class Submission extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="content-submission py-5">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <p className="content-header">
              Thank you for your interest in InBrace.
              <br />
              We're so excited you're taking the first step to wowing your patients starting on day 1. An InBrace representative will contact you shortly. 
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default Submission;
