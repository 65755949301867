import React, { Component } from 'react';
import './footer.scss';
import logo from '../../static/myinbrace-logo-gradient-transition.svg';
class Footer extends Component {
  render() {
    return (
      <div className="container">
        <div className="row py-0 py-lg-5 aos-init aos-animate" data-aos="fade" data-aos-duration="800">
          <div className="col-xl-6">
            {' '}
            {/*eslint-disable-next-line*/}
            <a href={this.props.provider_link}>
              <img src={logo} alt="InBrace" width="160" className="img-fluid" />
            </a>
            <p className="mt-3">
              InBrace is the most advanced,
              <br />
              invisible orthodontic solution in the world.
            </p>
            <p className="py-3">
              {/*eslint-disable-next-line*/}
              <a href={this.props.provider_link} className="btn btn-outline rounded-pill btn-sm mr-2 my-2">
                Become a Provider
              </a>
            </p>
          </div>
          <div className="col-xl-6">
            <div className="row">
              <div className="col-md-6">
                <h5 className="py-3">Navigation</h5>
                <div className="row">
                  <div className="col-6">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        {' '}
                        <a className="nav-link" href="https://inbrace.com/technology/">
                          Technology
                        </a>
                      </li>
                      <li className="nav-item">
                        {' '}
                        <a className="nav-link" href="https://inbrace.com/for-patients/">
                          For Patients
                        </a>
                      </li>
                      <li className="nav-item">
                        {' '}
                        <a className="nav-link" href="https://inbrace.com/for-doctors/">
                          For Doctors
                        </a>
                      </li>
                      <li className="nav-item">
                        {' '}
                        <a className="nav-link" href="https://inbrace.com/testimonials/">
                          Testimonials
                        </a>
                      </li>
                      <li className="nav-item">
                        {' '}
                        <a className="nav-link" href="https://inbrace.com/about-us/">
                          About us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        {' '}
                        <a href="https://portal.inbrace.com/" target="_blank" rel="noopener noreferrer" className="nav-link">
                          Login
                        </a>
                      </li>
                      <li className="nav-item">
                        {' '}
                        <a className="nav-link" href="https://inbrace.com/contact/">
                          Contact
                        </a>
                      </li>
                      <li className="nav-item">
                        {' '}
                        <a className="nav-link" href="https://inbrace.com/faq/">
                          FAQ
                        </a>
                      </li>
                      <li className="nav-item">
                        {' '}
                        <a
                          href="https://www.linkedin.com/jobs/swift-health-systems-inc-jobs-irvine-ca/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="nav-link"
                        >
                          Careers
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <h5 className="py-3 mt-5 mt-md-0">Headquarters</h5>
                <address className="text-gray">
                  111 Academy Drive Suite 150
                  <br />
                  Irvine California 92617
                  <br />
                  <a href="mailto:info@inbrace.com" className="d-block mt-3 text-gray">
                    info@inbrace.com
                  </a>
                </address>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-5 aos-init aos-animate" data-aos="fade" data-aos-duration="800">
          <div className="col-lg-6">
            <div className="row mb-3 mb-lg-0">
              <div className="col-md-6">
                <ul className="nav text-center social-nav mb-3 mb-md-0">
                  <li className="nav-item align-self-center text-gray">Public:</li>
                  <li className="nav-item">
                    {' '}
                    <a className="nav-link rounded-circle" href="https://www.facebook.com/inbrace" target="_blank" rel="noopener noreferrer">
                      <i className="zmdi zmdi-facebook"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    {' '}
                    <a className="nav-link rounded-circle" href="https://www.instagram.com/inbrace/" target="_blank" rel="noopener noreferrer">
                      <i className="zmdi zmdi-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="nav text-center social-nav">
                  <li className="nav-item align-self-center text-gray">Doctors:</li>
                  <li className="nav-item">
                    {' '}
                    <a className="nav-link rounded-circle" href="https://www.facebook.com/inbracedr" target="_blank" rel="noopener noreferrer">
                      <i className="zmdi zmdi-facebook"></i>
                    </a>
                  </li>
                  <li className="nav-item">
                    {' '}
                    <a className="nav-link rounded-circle" href="https://www.instagram.com/inbracedr" target="_blank" rel="noopener noreferrer">
                      <i className="zmdi zmdi-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <p className="py-2">
              <a href="https://inbrace.com/terms-and-conditions/" className="text-gray">
                Terms &amp; conditions
              </a>{' '}
              |{' '}
              <a href="https://inbrace.com/privacy-policy/" className="text-gray">
                Privacy policies
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default Footer;
