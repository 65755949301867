import { POST_INQIRY_FORM_PENDING, POST_INQIRY_FORM_SUCCESS, POST_INQIRY_FORM_ERROR, CHANGE_INQIRY_FORM_DATA, GET_IP_ADDRESS } from '../actions/inquiry_form';
import { browserInfo } from '../../helper/functions';
//JSON import
let input_data = require('../../json/form.json');
const states = require('../../json/states.json');
const countries = require('../../json/countries.json');

//Insert Country and State Data
input_data.filter(function (q) {
  return q.input_type === 'state';
})[0].option_array = input_data
  .filter(function (q) {
    return q.input_type === 'state';
  })[0]
  .option_array.concat(states);
input_data.filter(function (q) {
  return q.input_type === 'country';
})[0].option_array = input_data
  .filter(function (q) {
    return q.input_type === 'country';
  })[0]
  .option_array.concat(countries);

const initialState = {
  pending: false,
  input_data: input_data,
  inqurity_form_data: {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    state: '',
    postal_code: '',
    country: 'United States',
    user_type: '',
    other_usertype: '',
    scanner_check: '',
    source: '',
    other_soruce: '',
    website: '',
    social: '',
    browser_info: browserInfo(),
    ip_address: '',
  },
  error: null,
  success: false,
  form_validation: false,
  form_submit: false,
};

export function inquiryFormReducer(state = initialState, action) {
  switch (action.type) {
    case POST_INQIRY_FORM_PENDING:
      return {
        ...state,
        pending: true,
      };
    case POST_INQIRY_FORM_SUCCESS:
      return {
        ...state,
        success: true,
        pending: false,
        inqurity_form_data: action.inqurity_form_data,
      };

    case CHANGE_INQIRY_FORM_DATA:
      return {
        ...state,
        pending: false,
        inqurity_form_data: action.inqurity_form_data,
        form_validation: action.form_validation,
        input_data: action.input_data,
        form_submit: action.form_submit,
      };

    case POST_INQIRY_FORM_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      };

    case GET_IP_ADDRESS:
      return {
        ...state,
        inqurity_form_data: action.inqurity_form_data,
      };

    default:
      return state;
  }
}
export const getinquiryFormSubmit = (state) => state.inquiryFormReducer.form_submit;
export const getinquiryFormInputData = (state) => state.inquiryFormReducer.input_data;
export const getinquiryFormData = (state) => state.inquiryFormReducer.inqurity_form_data;
export const getinquiryFormSuccess = (state) => state.inquiryFormReducer.success;
export const getinquiryFormformvalidation = (state) => state.inquiryFormReducer.form_validation;
export const getinquiryFormPending = (state) => state.inquiryFormReducer.pending;
export const getinquiryFormError = (state) => state.inquiryFormReducer.error;
