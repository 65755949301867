import React, { Component } from 'react';
import TextBox from './textbox';
class DropDown extends Component {
  render() {
    return (
      <React.Fragment>
        <div key={this.props.input.id} className="form-group">
          <label className="input-label-form" htmlFor={this.props.input.input_id}>
            {this.props.input.name_label}
            {this.props.input.required ? <span className="required-span">*</span> : null}
          </label>
          <select
            id={this.props.input.input_id}
            defaultValue={this.props.input.value}
            onChange={this.props.changeHandler}
            onBlur={this.props.blurHandler}
            required={this.props.input.required ? true : false}
            className={this.props.input.required && this.props.form_submit && this.props.input.error ? 'form-control input-error' : 'form-control'}
            name={this.props.input.name ? this.props.input.name : this.props.input.input_id}
          >
            {this.props.input.option_array.map((i, index) => (
              <option key={index} value={i.name === '- Please Select -' ? '' : i.name}>
                {i.name}
              </option>
            ))}
            ;
          </select>
          {this.props.input.required && this.props.form_submit && this.props.input.error ? (
            <small className="error-message">Please make a selection.</small>
          ) : null}
        </div>
        {this.props.inquiry_form_data[this.props.input.input_id] &&
        this.props.inquiry_form_data[this.props.input.input_id] === 'Other' &&
        this.props.input.other_input ? (
          <TextBox input={this.props.input.other_input} changeHandler={this.props.changeHandler} inquiry_form_data={this.props.inquiry_form_data}></TextBox>
        ) : null}
      </React.Fragment>
    );
  }
}
export default DropDown;
