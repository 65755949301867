import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
// import Header from '../header/header';
// import Footer from '../footer/footer';
import FormContent from '../form/form';
import Submission from '../submission/submission';
import './content.scss';

// Redux Actions
import { postInquiryFormData, changeInquiryFormData, getIpAddress } from '../../redux/actions/inquiry_form';

// Redux Reducers
import {
  getinquiryFormData,
  getinquiryFormPending,
  getinquiryFormError,
  getinquiryFormSuccess,
  getinquiryFormformvalidation,
  getinquiryFormInputData,
  getinquiryFormSubmit,
} from '../../redux/reducers/inquiry_form';

class Content extends Component {
  componentWillMount() {
    try {
      fetch('https://api.ipify.org?format=jsonp?callback=?', {
        method: 'GET',
        headers: {},
      })
        .then((res) => {
          return res.text();
        })
        .then((ip) => {
          let inqurity_form_data = this.props.inquiry_form_data;
          inqurity_form_data.ip_address = ip;
          this.props.getIpAddress(this.props.inquiry_form_data);
        });
    } catch (error) {
      console.log('Error while fetching Ip Address' + error);
    }
  }
  render() {
    return (
      <div className="page">
        <div className="content">
          {this.props.success ? (
            <Submission />
          ) : (
            <FormContent
              form_validation={this.props.form_validation}
              inquiry_form_data={this.props.inquiry_form_data}
              inquiry_form_pending={this.props.inquiry_form_pending}
              inquiry_form_error={this.props.inquiry_form_error}
              handleChange={this.props.changeInquiryFormData}
              submitForm={this.props.postInquiryFormData}
              input_data={this.props.input_data}
              form_submit={this.props.form_submit}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    input_data: getinquiryFormInputData(state),
    inquiry_form_data: getinquiryFormData(state),
    inquiry_form_pending: getinquiryFormPending(state),
    inquiry_form_error: getinquiryFormError(state),
    success: getinquiryFormSuccess(state),
    form_validation: getinquiryFormformvalidation(state),
    form_submit: getinquiryFormSubmit(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      postInquiryFormData: postInquiryFormData,
      changeInquiryFormData: changeInquiryFormData,
      getIpAddress: getIpAddress,
    },
    dispatch
  );
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));
