import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../header/header';
import Footer from '../footer/footer';
import './not_found.scss';
import Ximage from '../../static/icon-x.svg';

class NotFound extends Component {
  render() {
    return (
      <div className="page">
        <header className="header">
          <Header provider_link="/" />
        </header>
        <div className="content">
          <section className="py-5 bg-pink">
            <div className="container">
              <div className="row justify-content-center vh-50 text-center">
                <div className="col align-self-center">
                  <img src={Ximage} className="img-fluid my-5" alt="Not Found" />
                  <h1 className="text-center">Uh-Oh!</h1>
                  <p className="text-center">We couldn’t find the page you are looking for.</p>
                  <p className="my-4 text-center">
                    <a href="https://inbrace.com" className="btn btn-pink rounded-pill scroll-item">
                      Go to Home
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-blue py-5">
            <div className="container">
              <div className="row py-0 py-lg-5">
                <div className="col-md-6 align-self-top aos-init aos-animate" data-aos-duration="800" data-aos="fade-right">
                  <h3>Looking to join our team?</h3>
                  <p>The team is rapidly growing, and we are always on the lookout for exceptional&nbsp;talent.</p>
                  <p className="my-4">
                    <a
                      href="https://www.linkedin.com/jobs/swift-health-systems-inc-jobs-irvine-ca/?position=1&amp;pageNum=0"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="link-blue"
                    >
                      Visit our careers page <i className="zmdi zmdi-arrow-right animated infinite fadeInLeft zmdi-hc-fw mr-3"></i>
                    </a>
                  </p>{' '}
                </div>
                <div className="col-md-6 align-self-top aos-init aos-animate" data-aos-duration="800" data-aos="fade-left">
                  <h3>Are you interested in becoming an InBrace&nbsp;provider?</h3>
                  <p>Please contact us to get started soon.</p>
                  <p className="my-4">
                    <a href="/" className="link-blue">
                      Get started <i className="zmdi zmdi-arrow-right animated infinite fadeInLeft zmdi-hc-fw mr-3"></i>
                    </a>
                  </p>{' '}
                </div>
              </div>
            </div>
          </section>
        </div>

        <footer className="footer py-5 overflow-hidden">
          <Footer provider_link="/" />
        </footer>
      </div>
    );
  }
}

export default withRouter(NotFound);
