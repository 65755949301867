import Axios from 'axios';

// Action Types
export const POST_INQIRY_FORM_PENDING = 'POST_INQIRY_FORM_PENDING';
export const POST_INQIRY_FORM_SUCCESS = 'POST_INQIRY_FORM_SUCCESS';
export const POST_INQIRY_FORM_ERROR = 'POST_INQIRY_FORM_ERROR';
export const CHANGE_INQIRY_FORM_DATA = 'CHANGE_INQIRY_FORM_DATA';
export const GET_IP_ADDRESS = 'GET_IP_ADDRESS';

// GET IP ADDRESS
// -----------------------------------------------------------------------------
export function getIpAddress(inqurity_form_data) {
  return {
    type: GET_IP_ADDRESS,
    inqurity_form_data: inqurity_form_data,
  };
}

// CHANGE
// -----------------------------------------------------------------------------
export function changeInquiryFormData(inqurity_form_data, input_data, form_validation, form_submit) {
  return {
    type: CHANGE_INQIRY_FORM_DATA,
    inqurity_form_data: inqurity_form_data,
    form_validation: form_validation,
    input_data: input_data,
    form_submit: form_submit,
  };
}

// POST
// -----------------------------------------------------------------------------
export function postInquiryFormData() {
  return (dispatch, getState) => {
    let { inqurity_form_data } = getState().inquiryFormReducer;
    var data = inqurity_form_data;

    dispatch(postInquiryFormDataPending());
    Axios.post('/email', data)
      .then((res) => {
        try {
          window.parent.postMessage('formSubmit', 'https://inbrace.com');
        } catch(e) {
          console.warn(e);
        }

        dispatch(postInquiryFormDataSuccess(data));
      })
      .catch((error) => {
        dispatch(postInquiryFormDataError(error));
      });
  };
}

export function postInquiryFormDataPending() {
  return {
    type: POST_INQIRY_FORM_PENDING,
  };
}

export function postInquiryFormDataSuccess(data) {
  return {
    type: POST_INQIRY_FORM_SUCCESS,
    inqurity_form_data: data,
  };
}

export function postInquiryFormDataError(error) {
  return {
    type: POST_INQIRY_FORM_ERROR,
    error: error,
  };
}

// export function changeInquiryFormDataSuccess(data) {
//   return {
//     type: CHANGE_INQIRY_FORM_DATA,
//     inqurity_form_data: data,
//   };
// }
