import React, { Component } from 'react';
import TextBox from './input/textbox';
import DropDown from './input/dropdown';
import './form.scss';
import { validateEmail, validatePhone } from '../../helper/functions';
class FormContent extends Component {
  constructor(props) {
    super(props);
    this.submitHandler.bind(this);
    this.changeHandler.bind(this);
  }
  //Button onSubmit Event Handler
  submitHandler = (event) => {
    event.preventDefault();
    this.validateData(this.props.inquiry_form_data, this.props.input_data, true);
    // event.target.className += ' was-validated';
    if (this.props.form_validation) {
      this.props.submitForm();
    }
  };

  validateData = (inquiry_form_data, input_data, form_submit) => {
    let form_validation = false;
    if (
      inquiry_form_data.first_name &&
      inquiry_form_data.last_name &&
      inquiry_form_data.email &&
      inquiry_form_data.phone &&
      inquiry_form_data.state &&
      inquiry_form_data.country &&
      inquiry_form_data.scanner_check &&
      inquiry_form_data.source &&
      inquiry_form_data.website &&
      validateEmail(inquiry_form_data.email) &&
      validatePhone(inquiry_form_data.phone)
    ) {
      form_validation = true;
    }
    input_data.forEach((input) => {
      if (input.required && !input.value) {
        input.error = true;
      } else if (input.required && input.value) {
        if (input.input_id === 'email' && !validateEmail(input.value)) {
          input.error = true;
        } else if (input.input_id === 'phone' && !validatePhone(input.value)) {
          input.error = true;
        } else {
          input.error = false;
        }
      } else {
        input.error = false;
      }
    });
    this.props.handleChange(inquiry_form_data, input_data, form_validation, form_submit);
  };

  //onBlur event Handler
  blurHandler = (event) => {
    let inquiry_form_data = this.props.inquiry_form_data;
    let input_data = this.props.input_data;
    input_data.filter(function (q) {
      return q.input_id === event.target.id;
    })[0].value = event.target.value;
    inquiry_form_data[event.target.id] = event.target.value;
    this.validateData(inquiry_form_data, input_data, this.props.form_submit);
    this.forceUpdate();
  };
  //onChange event Handler
  changeHandler = (event) => {
    let inquiry_form_data = this.props.inquiry_form_data;
    let input_data = this.props.input_data;
    input_data.filter(function (q) {
      return q.input_id === event.target.id;
    })[0].value = event.target.value;
    inquiry_form_data[event.target.id] = event.target.value;
    this.validateData(inquiry_form_data, input_data, this.props.form_submit);
    this.forceUpdate();
  };
  //Display all the inputs from the json array
  displayInputData = () => {
    return this.props.input_data
      .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
      .map((input) => (input.type === 'textbox' ? this.displayTextBox(input) : input.type === 'select' ? this.displaySelect(input) : null));
  };
  //Display all the textbox from the json object
  displayTextBox = (input) => {
    return (
      <TextBox
        input={input}
        form_submit={this.props.form_submit}
        changeHandler={this.changeHandler}
        blurHandler={this.blurHandler}
        inquiry_form_data={this.props.inquiry_form_data}
      ></TextBox>
    );
  };
  //Display all the DropDown from the json object
  displaySelect = (input) => {
    return (
      <DropDown
        input={input}
        form_submit={this.props.form_submit}
        changeHandler={this.changeHandler}
        inquiry_form_data={this.props.inquiry_form_data}
      ></DropDown>
    );
  };
  render() {
    return (
      <React.Fragment>
        <div className="form-container">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <p className="sub-header">Please enter your practice info below and an InBrace Business Growth Consultant will contact you shortly.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <form className="form-content needs-validation" noValidate onSubmit={this.submitHandler}>
                {this.displayInputData()}
                <button type="submit" className="btn btn-primary btn-override">
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default FormContent;
